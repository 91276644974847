import { graphql } from "gatsby";
import {
  LeadSection,
  Post,
  PostContentsContainer,
  Button,
  CardGrid,
  PostContentsTitle,
  Title,
  PostAuthor,
} from "@lleed-partners/factor";

import { Icon } from "../fragments/Icon";
import { MDXRenderer } from "gatsby-plugin-mdx";
import "../fragments/Card/fragments.tsx";
import {
  useI18next,
  Link as GatsbyLink,
  Helmet,
} from "gatsby-plugin-react-i18next";
import { ArrowRight32 } from "@carbon/icons-react";
import { mapElasticSearchResults } from "../graphql/helpers";
import { ServiceCard, TServiceCardData } from "../fragments/Card/service";
import { Layout } from ".";
import { Header } from "../fragments/Header";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { InsightCard, TInsightCardData } from "../fragments/Card/insight";
import { asBackground } from "../fragments/styles";

const InsightLayout = ({
  data: {
    post: { frontmatter, body },
    relatedServices: rawRelatedServices,
    relatedInsights: rawRelatedInsights,
  },
}) => {
  const relatedServices = mapElasticSearchResults(rawRelatedServices);
  const relatedInsights = mapElasticSearchResults(rawRelatedInsights);

  const { t } = useI18next();

  let dates = [
    t("Published on {{date}}", {
      date: frontmatter.createdFormatted,
    }),
  ];

  if (
    Math.abs(new Date(frontmatter.created) - new Date(frontmatter.modified)) > 1
  ) {
    dates = [
      t("Updated on {{date}}", {
        date: frontmatter.modifiedFormatted,
      }),
    ];
  }

  return (
    <Layout navProps={{ showSubNav: false }}>
      <article>
        <Helmet>
          <meta property="og:type" content="article" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Article",
              headline: frontmatter.title,
              description: frontmatter.excerpt,
              image: (() => {
                if (frontmatter.localHeaderImage) {
                  return [getSrc(frontmatter.localHeaderImage)];
                } else if (frontmatter.headerImage) {
                  return [getSrc(frontmatter.headerImage)];
                } else {
                  return [];
                }
              })(),
              datePublished: frontmatter.created,
              dateModified: frontmatter.modified,
              author: (() => {
                if (frontmatter.author) {
                  return [
                    {
                      "@type": "Person",
                      name: `${frontmatter.author.firstName} ${frontmatter.author.lastName}`,
                      image: getSrc(frontmatter.author.picture),
                    },
                  ];
                }
              })(),
              publisher: {
                "@type": "Organization",
                name: "Lleed & Partners",
                url: "https://lleedpartners.com/",
              },
            })}
          </script>
        </Helmet>
        <Header frontmatter={frontmatter} />
        <Post>
          {frontmatter.author && (
            <PostAuthor
              dates={dates}
              author={frontmatter.author}
              picture={
                <GatsbyImage image={getImage(frontmatter.author.picture)} />
              }
            />
          )}
          <p
            style={{
              fontStyle: "italic",
              fontSize: "1.4em",
            }}
          >
            {frontmatter.excerpt}
          </p>
          <MDXRenderer
            localImages={frontmatter.localImages}
            remoteImages={frontmatter.remoteImages}
          >
            {body}
          </MDXRenderer>
          {relatedServices.edges.length > 0 ? (
            <>
              <PostContentsTitle
                title={<Title title={t("Related services")} />}
              />
              <PostContentsContainer position="center-full">
                <CardGrid intent="white" bordered>
                  {relatedServices.edges
                    .slice(0, 3)
                    .map(({ node: service }: { node: TServiceCardData }) => (
                      <ServiceCard service={service} />
                    ))}
                </CardGrid>
              </PostContentsContainer>
            </>
          ) : null}
          <PostContentsContainer position="center-right-full">
            <LeadSection
              title={t("Get in touch")}
              subtitle={t(
                "Lleed & Partners experts are here to help you in the digitalisation process, from strategy to implementation. Let's discuss your current needs, and how we can help you achieve your goals."
              )}
              inline
              intent="grey"
              cta={
                <Button
                  as={GatsbyLink}
                  rightIcon={<Icon icon={<ArrowRight32 />} />}
                  to="/contact/"
                >
                  {t("Contact us")}
                </Button>
              }
            />
          </PostContentsContainer>
          {relatedInsights.edges.length > 0 ? (
            <>
              <PostContentsTitle
                title={<Title title={t("Related insights")} />}
              />
              <PostContentsContainer position="center-full">
                <CardGrid intent="white" bordered>
                  {relatedInsights.edges
                    .slice(0, 3)
                    .map(({ node: insight }: { node: TInsightCardData }) => (
                      <InsightCard insight={insight} />
                    ))}
                </CardGrid>
              </PostContentsContainer>
            </>
          ) : null}
        </Post>
      </article>
    </Layout>
  );
};

export default InsightLayout;

export const pageQuery = graphql`
  query (
    $id: String!
    $language: String!
    $suggestedQuery: JSON!
    $suggestedQueryInsight: JSON!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    post: mdx(id: { eq: $id }) {
      body
      frontmatter {
        category
        created
        createdFormatted: created(
          fromNow: false
          locale: $language
          formatString: "LL"
        )
        modified
        modifiedFormatted: modified(
          fromNow: false
          locale: $language
          formatString: "LL"
        )
        excerpt
        author {
          firstName
          lastName
          role
          isMember
          location
          picture {
            childImageSharp {
              gatsbyImageData
            }
          }
          linkedin
        }
        headerImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        localHeaderImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        localImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        remoteImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        slug
        title
      }
    }

    relatedServices: elastic {
      search(body: $suggestedQuery)
    }

    relatedInsights: elastic {
      search(body: $suggestedQueryInsight)
    }
  }
`;
