import { Card, ICardProps, Link } from "@lleed-partners/factor";
import { Link as GatsbyLink, useI18next } from "gatsby-plugin-react-i18next";
import { Icon, TPreloadedIconNames } from "../Icon";
import { ArrowRight32 } from "@carbon/icons-react";
import { trail } from "..";

export type TServiceCardData = {
  frontmatter: {
    title: string;
    slug: string;
    icon: TPreloadedIconNames;
    description: string;
  };
};

export interface IServiceCardProps extends ICardProps {
  service: TServiceCardData;
}

export const ServiceCard = ({ service, ...props }: IServiceCardProps) => {
  const { t } = useI18next();

  return (
    <Card
      size="xLarge"
      interactive
      as={GatsbyLink}
      to={trail(service.frontmatter.slug)}
      title={service.frontmatter.title}
      icon={<Icon name={service.frontmatter.icon} />}
      trackId={`card-service-${service.frontmatter.title}`}
      cta={
        <Link
          as="span"
          to={trail(service.frontmatter.slug)}
          icon={<Icon icon={<ArrowRight32 />} />}
        >
          {t("Learn more")}
        </Link>
      }
      {...props}
    >
      {service.frontmatter.description}
    </Card>
  );
};
