const mapElasticSearchResults = (data) => {
  return {
    edges: data.search.hits.hits.map(({ _source: hit }) => {
      return { node: hit }
    })
  }
}

module.exports = {
  mapElasticSearchResults
}
