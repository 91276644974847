import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { LeadSection } from "@lleed-partners/factor";
import { asBackground } from "../styles";
import { Helmet, useI18next } from "gatsby-plugin-react-i18next";
import { capitalize } from "..";

export const Header = ({ frontmatter }) => {
  let media = undefined;
  let leadMediaSrc = undefined;

  if (frontmatter.localHeaderImage) {
    media = (
      <GatsbyImage
        image={getImage(frontmatter.localHeaderImage)}
        style={asBackground}
        loading="eager"
      />
    );

    leadMediaSrc = getSrc(frontmatter.localHeaderImage);
  } else if (frontmatter.headerImage) {
    media = (
      <GatsbyImage
        image={getImage(frontmatter.headerImage)}
        style={asBackground}
        loading="eager"
      />
    );

    leadMediaSrc = getSrc(frontmatter.headerImage);
  }

  const { t } = useI18next();

  return (
    <>
      <LeadSection
        category={capitalize(t(frontmatter.category))}
        padTop
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        backgroundMedia={media}
      />
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta
          name="description"
          content={frontmatter.description || frontmatter.excerpt}
        />
        <meta
          name="og:description"
          content={frontmatter.description || frontmatter.excerpt}
        />
        <meta property="og:title" content={frontmatter.title} />
        {leadMediaSrc ? (
          <meta property="og:image" content={leadMediaSrc} />
        ) : null}
        <meta
          property="og:url"
          content={`https://lleedpartners.com${frontmatter.slug}`}
        />
      </Helmet>
    </>
  );
};
