import { Card, ICardProps, Link } from "@lleed-partners/factor";
import { Link as GatsbyLink, useI18next } from "gatsby-plugin-react-i18next";
import { Icon, TPreloadedIconNames } from "../Icon";
import { ArrowRight32 } from "@carbon/icons-react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { asBackground, aspectRatio } from "../styles";
import { trail } from "..";

export type TInsightCardData = {
  frontmatter: {
    title: string;
    slug: string;
    icon: TPreloadedIconNames;
    excerpt: string;
    headerImage?: Record<string, unknown>;
  };
};

export interface IInsightCardProps extends ICardProps {
  insight: TInsightCardData;
}

export const InsightCard = ({ insight, ...props }: IInsightCardProps) => {
  const { t } = useI18next();

  let media = undefined;

  if (insight.frontmatter.localHeaderImage) {
    media = (
      <GatsbyImage
        image={getImage(insight.frontmatter.localHeaderImage)}
        style={aspectRatio(16 / 9)}
        loading="eager"
      />
    );
  } else if (insight.frontmatter.headerImage) {
    media = (
      <GatsbyImage
        image={getImage(insight.frontmatter.headerImage)}
        style={aspectRatio(16 / 9)}
        loading="eager"
      />
    );
  }

  return (
    <Card
      size="xLarge"
      interactive
      as={GatsbyLink}
      to={trail(insight.frontmatter.slug)}
      title={insight.frontmatter.title}
      media={media}
      trackId={`card-insight-${insight.frontmatter.title}`}
      cta={
        <Link
          as="span"
          to={trail(insight.frontmatter.slug)}
          icon={<Icon icon={<ArrowRight32 />} />}
        >
          {t("Read more")}
        </Link>
      }
      {...props}
    >
      {insight.frontmatter.excerpt}
    </Card>
  );
};
